import {Footer} from '../Footer';
import {MainHome} from '../MainHome';
import {PortfolioScreen} from '../PortfolioScreen';
import {QIAboutUs} from '../QIAboutUs';
import {QIServiceTypes} from '../QIServiceTypes';
import {QISolution} from '../QISolution';

const QIHome = () => {
  return (
    <>
      <div className={'flex justify-center'}>
        <div
          style={{
            height: window.innerWidth < 768 ? '151vh' : '110vh',
            width: '100vw',
            backgroundSize: 'cover',
          }}>
          <MainHome />
          <QIAboutUs />
          <QISolution />
          <QIServiceTypes />
          <PortfolioScreen />
          <Footer />
        </div>
      </div>
    </>
  );
};

export default QIHome;
