import {Image} from 'antd';
import {motion} from 'framer-motion';
import {useEffect, useState} from 'react';

import begin from '../../../assets/Images/1.jpg';

const PartOne = () => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const id = setInterval(() => {
      setIndex(state => {
        return state + 1;
      });
    }, 2000);
    return () => clearInterval(id);
  }, []);

  return(
    <>
      <div className={'pt-12 md:p-24'}>
        <span className={'text-2xl md:text-4xl text-white font-bold'}>Our </span>
        <span className={'text-2xl md:text-4xl font-bold text-yellow-500'}>Journey</span>
      </div>
      <div
        className={'flex lg:flex-row flex-row-reverse justify-center'}>
        <motion.div
          initial={{
            opacity: 0,
            // if odd index card,slide from right instead of left
            x: index % 2 === 5 ? 30 : -30,
          }}
          whileInView={{
            opacity: 1,
            x: 0, // Slide in to its original position
            transition: {
              duration: 3, // Animation duration
            },
          }}
          viewport={{once: false}}>
          <div
            className={'flex flex-col justify-center pl-6 text-white border rounded-3xl w-52 h-80 lg:w-96 lg:h-96 p-1 lg:p-12 lg:translate-x-16'}>
            <h1 className={'text-xl lg:text-3xl'}> Our Humble Beginnings </h1>
            <p className={'text-sm w-44 lg:w-60'}> I embarked alone into the uncharted terrain of software development
            with the addition of a single partner, our journey of humble beginnings unfolded, driven by a shared
            passion for innovation and coding excellence !
            </p>
          </div>
        </motion.div>
        <div className={'flex md:mr-0 justify-center lg:flex flex-col-reverse lg:flex-row items-center'}>
          <div className={'w-32 h-32 lg:w-52 lg:h-52 lg:translate-x-8'}>
            <motion.div
              whileHover={{scale: 1.2}}
              whileTap={{scale: 0.8}}
            >
              <Image src={begin}
                className={'border-header-color border-4 border-yellow-500 rounded-3xl'}
                preview={false}
              />
            </motion.div>
          </div>
          <div className={'lg:ml-12'}>
            <motion.div
              initial={{
                opacity: 0,
                x: index % 2 === 0 ? -30 : 30,
              }}
              whileInView={{
                opacity: 1,
                x: 0, // Slide in to its original position
                //rotate: [0, 360], // Add rolling effect from 0 to 360 degrees
                transition: {
                  duration: 2, // Animation duration
                  ease: 'easeInOut', // Easing function for smooth rolling
                },
              }}
              viewport={{once: false}}
            >
              <h1 className={'text-sm py-2 lg:text-3xl text-yellow-500 font-bold'}> 2021-2022 </h1>
            </motion.div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PartOne;
