import {motion} from 'framer-motion';

import {AboutSlider} from './AboutUs/AboutSlider';
import {PartFour, PartOne, PartThree, PartTwo} from './parts';

const OurJourney = () => {
  return (
    <div className={'bg-zinc-950 overflow-hidden'}>
      <PartOne />
      <PartTwo />
      <PartThree />
      <PartFour />
      <div className={'flex justify-center mt-9'}>
        <motion.div
          initial={{
            scale: 1, // Initial scale (normal size)
            opacity: 0, // Initial opacity
            x: 30, // Start position off-screen to the right
          }}
          whileInView={{
            opacity: 1,
            x: 0, // Slide in to its original position
            scale: [1, 1.2, 1.2, 1], // Normal, then scale up, down, stamp effect, and back to normal
            borderRadius: ['20%', '20%', '50%', '50%', '20%', '20%', '20%'], // Border radius animation
          }}
          transition={{
            duration: 3, // Total duration of the animation
            times: [0, 0.25, 0.5, 0.5, 0.75, 0.875, 0.975, 1], // Define when each keyframe should occur
          }}
          viewport={{once: false}}
        >
        </motion.div>
      </div>

      <AboutSlider />

    </div>
  );
};

export default OurJourney;
