import {motion} from 'framer-motion';
import React from 'react';

import backgroundvideo from '../../assets/Images/background.mp4';

const MainHome = () => {
  const scrollToBottom = () => {
    window.scrollTo({
      // top: document.body.scrollHeight,
      top: window.innerHeight,
      behavior: 'smooth',
    });
  };

  return (
    <section
      className={'relative min-h-screen bg-black h-screen flex flex-col justify-center items-center text-center text-white'}>
      <video
        autoPlay
        loop
        muted
        id={'myVideo'}
        className={'absolute inset-0 w-full h-full object-cover z-0'} // Cover the whole background
      >
        <source src={backgroundvideo} type={'video/mp4'} />
        Your browser does not support the video tag.
      </video>
      <motion.h1
        className={'text-3xl md:text-5xl font-bold mb-6'}
        initial={{opacity: 0, y: -50}}
        animate={{opacity: 1, y: 0}}
        transition={{duration: 1}}
      >
        Welcome to <span className={'text-orange-500'}>Quantum Infotech!</span>
      </motion.h1>
      <motion.p
        className={'text-base md:text-lg mb-8 px-4 font-bold text-center'}
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        transition={{delay: 0.5, duration: 1}}
      >
        ONE STOP DESTINATION FOR ALL
        YOUR SOFTWARES
      </motion.p>
      <motion.div
        className={'mt-10'}
        initial={{opacity: 0, y: 50}}
        animate={{opacity: 1, y: 0}}
        transition={{delay: 1, duration: 1}}
      >
        {/*<p className={'text-sm uppercase tracking-widest'}>Scroll</p>*/}
        <button className={'scroll-button'} onClick={scrollToBottom}>Scroll</button>
        <svg
          className={'w-6 h-6 mx-auto animate-bounce'}
          fill={'none'}
          stroke={'currentColor'}
          viewBox={'0 0 24 24'}
          xmlns={'http://www.w3.org/2000/svg'}
        >
          <path
            strokeLinecap={'round'}
            strokeLinejoin={'round'}
            strokeWidth={'2'}
            d={'M19 9l-7 7-7-7'}
          ></path>
        </svg>
      </motion.div>
    </section>
  );
};

export default MainHome;
