import {Button, Image, List} from 'antd';
import {motion} from 'framer-motion';
import React from 'react';
import {Link} from 'react-router-dom';

import QuantumInfotech from '../../assets/Images/Quantum-Infotech_-_Copy.png';

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: window.scrollTop = 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className={'pt-20 md:py-20 p-8 bg-zinc-950 md:flex md:justify-center'}>
      <div className={'max-w-7xl sm:px-6 '}>
        {/* Top area: Blocks */}
        <div className={'grid md:grid-cols-12 gap-8 lg:gap-20 mb-8 md:mb-12 mdpx-10'}>
          {/* 1st block */}
          <div className={'md:col-span-4'}>
            <div className={'mb-10'}>
              {/* Logo */}
              <Image src={QuantumInfotech} preview={false} alt={'Quantum Infotech'} className={'w-32'} />
            </div>
            <div className={'text-gray-400'}>
              <h1 className={'text-white font-semibold text-xl pb-4'}>Quantum Infotech</h1>
              <p className={'pt-4'}>
                E-mail:
                <a href={'mailto:contact@quantuminfotechs.com'} className={'text-blue-400 hover:underline ml-1'}>
                  contact@quantuminfotechs.com
                </a>
              </p><p className={'pt-4 md:pl-12'}>
                <a href={'mailto:quantum.infotechs@gmail.com'} className={'text-blue-400 hover:underline ml-1'}>
                quantum.infotechs@gmail.com
                </a>
              </p>
            </div>
          </div>

          <div className={'w-full flex flex-col md:flex-row gap-8'}>
            {/* Products */}
            <div>
              <List
                header={<h2 className={'text-white text-lg'}>Products</h2>}
                itemLayout={'horizontal'}
                dataSource={[
                  {title: 'Banking ERP software', link: '/contactus'},
                  {title: 'Customer Relationship Management (CRM)', link: '/contactus'},
                  {title: 'Telehealth Platform', link: '/contactus'},
                  {title: 'Personal Finance Management App', link: '/contactus'},
                  {title: 'Electronic Health Record (EHR)', link: '/contactus'},
                  {title: 'Medication Management App', link: '/contactus'},
                ]}
                renderItem={item => (
                  <List.Item className={'leading-10 text-sm md:w-56 '}>
                    <List.Item.Meta
                      title={(
                        <div className={'text-white leading-tight'}>
                          <a href={item.link}>{item.title}</a>
                        </div>
                      )} />
                  </List.Item>
                )} />
            </div>

            {/* Services */}
            <div>
              <List
                header={<h2 className={'text-white text-lg'}>Services</h2>}
                itemLayout={'horizontal'}
                dataSource={[
                  {title: 'Mobile App Development', link: '/details/1'},
                  {title: 'Web App Development', link: '/details/2'},
                  {title: 'ERP Software', link: '/details/3'},
                  {title: 'UI/UX Design', link: '/details/4'},
                  {title: 'Digital Marketing', link: '/details/5'},
                  {title: 'Content Writing', link: '/details/6'},
                  {title: 'Other Services', link: '/services'},
                ]}
                renderItem={item => (
                  <List.Item className={'leading-tight text-sm md:w-44'}>
                    <List.Item.Meta
                      title={(
                        <div className={'text-white leading-3'}>
                          <a href={item.link}>{item.title}</a>
                        </div>
                      )} />
                  </List.Item>
                )} />
            </div>

            {/* Career */}
            <div>
              <List
                header={<h2 className={'text-white text-lg'}>Career</h2>}
                itemLayout={'horizontal'}
                dataSource={[
                  {title: 'Company Overview', link: '/Career'},
                  {title: 'Current Job Openings', link: '/Career'},
                  {title: 'Internships and Programs', link: '/Career'},
                  {title: 'Legal Information', link: '/TermsAndConditions'},
                ]}
                renderItem={item => (
                  <List.Item className={'leading-tight text-sm md:w-44'}>
                    <List.Item.Meta
                      title={(
                        <div className={'text-white leading-3'}>
                          <a href={item.link}>{item.title}</a>
                        </div>
                      )} />
                  </List.Item>
                )} />
            </div>

            {/* Blogs */}
            <div>
              <List
                header={<h2 className={'text-white text-lg'}>Blogs</h2>}
                itemLayout={'horizontal'}
                dataSource={[
                  {title: 'Latest Insights', link: 'blog.quantuminfotechs.com'},
                  {title: 'Tech News', link: 'blog.quantuminfotechs.com'},
                  {title: 'Development Trends', link: 'blog.quantuminfotechs.com'},
                  {title: 'Career Tips', link: 'blog.quantuminfotechs.com'},
                ]}
                renderItem={item => (
                  <List.Item className={'leading-tight text-sm md:w-44'}>
                    <List.Item.Meta
                      title={(
                        <div className={'text-white leading-3'}>
                          <a href={item.link}>{item.title}</a>
                        </div>
                      )} />
                  </List.Item>
                )} />
            </div>
          </div>
        </div>

        {/* Bottom area */}
        <div className={'md:flex md:items-center md:justify-between '}>
          <div className={'py-4 md:py-0 flex flex-col md:flex-row'}>
            <Link to={'/TermsAndConditions'}>
              <Button className={'bg-inherit text-gray-400 border-none'}>Terms & Conditions </Button>
            </Link>
            <Link to={'/PrivacyPolicy'}>
              <Button className={'bg-inherit text-gray-400 border-none'}>Privacy Policy</Button>
            </Link>
          </div>
          {/* Social links */}
          <div className={'flex mb-4 md:order-1 md:ml-4 md:mb-0'}>
            <a href={'https://x.com/quantumin2024'}
              className={'flex mr-2 justify-center items-center text-yellow-500 border-2 border-yellow-500 bg-gray-800 hover:text-gray-100 hover:bg-yellow-500 rounded-full transition duration-150 ease-in-out'}
              aria-label={'Twitter'} target={'_blank'} rel={'noreferrer'}>
              <svg className={'w-8 h-8 fill-current'} viewBox={'0 0 32 32'} xmlns={'http://www.w3.org/2000/svg'}>
                <path
                  d={'m13.063 9 3.495 4.475L20.601 9h2.454l-5.359 5.931L24 23h-4.938l-3.866-4.893L10.771 23H8.316l5.735-6.342L8 9h5.063Zm-.74 1.347h-1.457l8.875 11.232h1.36l-8.778-11.232Z'} />
              </svg>
            </a>

            <a href={'https://www.facebook.com/profile.php?id=61554523462774'} target={'_blank'}
              className={'flex mr-2 justify-center items-center text-yellow-500  border-2 border-yellow-500 bg-gray-800 hover:text-gray-100 hover:bg-yellow-500 rounded-full transition duration-150 ease-in-out'}
              aria-label={'Facebook'} rel={'noreferrer'}>
              <svg className={'w-8 h-8 fill-current'} viewBox={'0 0 32 32'} xmlns={'http://www.w3.org/2000/svg'}>
                <path
                  d={'M14.023 24L14 17h-3v-3h3v-2c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V14H21l-1 3h-2.72v7h-3.257z'} />
              </svg>
            </a>

            <a
              href={'https://www.instagram.com/quantum.infotech?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=='}
              className={'flex mr-2 justify-center items-center text-yellow-500 border-2 border-yellow-500 bg-gray-800 hover:text-gray-100 hover:bg-yellow-500 rounded-full transition duration-150 ease-in-out'}
              aria-label={'Instagram'} target={'_blank'} rel={'noreferrer'}>
              <svg className={'w-8 h-8 fill-current'} viewBox={'0 0 32 32'} xmlns={'http://www.w3.org/2000/svg'}>
                <circle cx={'20.145'} cy={'11.892'} r={'1'} />
                <path
                  d={'M16 20c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zm0-6c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2z'} />
                <path
                  d={'M20 24h-8c-2.056 0-4-1.944-4-4v-8c0-2.056 1.944-4 4-4h8c2.056 0 4 1.944 4 4v8c0 2.056-1.944 4-4 4zm-8-14c-.935 0-2 1.065-2 2v8c0 .953 1.047 2 2 2h8c.935 0 2-1.065 2-2v-8c0-.935-1.065-2-2-2h-8z'} />
              </svg>
            </a>

            <a href={'http://linkedin.com/company/quantum-infotechs'}
              className={'flex mr-2 justify-center items-center text-yellow-500 border-2 border-yellow-500 bg-gray-800 hover:text-gray-100 hover:bg-yellow-500 rounded-full transition duration-150 ease-in-out'}
              aria-label={'Linkedin'} target={'_blank'} rel={'noreferrer'}>
              <svg className={'w-8 h-8 fill-current'} viewBox={'0 0 32 32'} xmlns={'http://www.w3.org/2000/svg'}>
                <path
                  d={'M23.3 8H8.7c-.4 0-.7.3-.7.7v14.7c0 .3.3.6.7.6h14.7c.4 0 .7-.3.7-.7V8.7c-.1-.4-.4-.7-.8-.7zM12.7 21.6h-2.3V14h2.4v7.6h-.1zM11.6 13c-.8 0-1.4-.7-1.4-1.4 0-.8.6-1.4 1.4-1.4.8 0 1.4.6 1.4 1.4-.1.7-.7 1.4-1.4 1.4zm10 8.6h-2.4v-3.7c0-.9 0-2-1.2-2s-1.4 1-1.4 2v3.8h-2.4V14h2.3v1c.3-.6 1.1-1.2 2.2-1.2 2.4 0 2.8 1.6 2.8 3.6v4.2h.1z'} />
              </svg>
            </a>
          </div>

          <div className={'text-gray-400 text-sm '}>
            All rights reserved. &copy; Quantuminfotechs.com
          </div>
        </div>
      </div>

      {/*top button*/}
      <motion.div
        className={'lg:relative lg:-bottom-20 lg:left-20 p-4 text-center'}
        initial={{opacity: 0, y: 50}}
        animate={{opacity: 1, y: 0}}
        transition={{delay: 1, duration: 1}}>
        <svg
          className={'w-6 h-6 mx-auto animate-bounce text-white'}
          fill={'none'}
          stroke={'currentColor'}
          viewBox={'0 0 24 24'}
          xmlns={'http://www.w3.org/2000/svg'}>
          <path
            strokeLinecap={'round'}
            strokeLinejoin={'round'}
            strokeWidth={'2'}
            d={'M5 15l7-7 7 7'}
          ></path>
        </svg>
        <button className={'scroll-button text-white'} onClick={scrollToTop}>Scroll Up</button>
      </motion.div>
    </div>
  );
};

export default Footer;
