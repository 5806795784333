import './App.css';
import {Route, BrowserRouter as Router, Routes} from 'react-router-dom';

import {Career} from './screenComponents/Career';
import {ContactUs} from './screenComponents/ContactUs';
import {PrivacyPolicy} from './screenComponents/Footer/PrivacyPolicy';
import {TermsAndCondition} from './screenComponents/Footer/TermsAndConditions';
import NavigationBar from './screenComponents/NavigationBar/NavigationBar';
import {AboutUs} from './screenComponents/OurJourney/AboutUs';
import {QIHome} from './screenComponents/QIHome';
import {QIProvide} from './screenComponents/QIProvide';
import {QIServices} from './screenComponents/QIProvide/QIServices';
import {ServicesDetails} from './screenComponents/QIProvide/QIServices/ServicesDetails';

function App() {
  return (
    <Router>
      <NavigationBar />
      <Routes>
        <Route path={'/'} element={<QIHome />} />
        <Route path={'/services'} element={<QIServices />} />
        <Route path={'/Career'} element={<Career />} />
        <Route path={'/aboutus'} element={<AboutUs />} />
        <Route path={'/contactus'} element={<ContactUs />} />
        <Route path={'/details/:id'} element={<ServicesDetails />} />
        <Route path={'/services'} element={<QIProvide />} />
        <Route path={'/contactus'} element={<ContactUs />} />
        <Route path={'/TermsAndConditions'} element={<TermsAndCondition />} />
        <Route path={'/PrivacyPolicy'} element={<PrivacyPolicy />} />
      </Routes>
    </Router>
  );
}

export default App;
