import {Image} from 'antd';
import {useEffect} from 'react';

import TermsAndConditionsBanner from '../../../assets/Images/TermsAndConditions.png';
import TermsAndConditionsMain from '../../../assets/Images/TermsAndConditions.svg';
import {Footer} from '../index';

const TermsAndCondition = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return(
    <div className={'bg-zinc-950'}>
      <div style={{
        backgroundImage: window.innerWidth < 768 ? `url(${TermsAndConditionsBanner})` : `url(${TermsAndConditionsBanner})`,
        height: '60vh',
        backgroundSize: 'cover',
        backgroundBlendMode: 'hard-light',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}>
        <div className={'absolute md:left-20 mt-20 md:ml-30'}>
          <h1 className={'text-4xl md:font-bold md:text-6xl text-yellow-500'}>
            <p>Terms </p>and
            <p>Conditions</p> </h1>
        </div>
      </div>
      <div className={'flex md:px-52 md:py-8 flex-col md:flex-row'}>
        <div className={'px-6 md:py-28 md:flex justify-center md:justify-start '}>
          <Image src={TermsAndConditionsMain} preview={false} height={'60vh'} width={'45vh'} className={'flex items-center justify-center '} />
        </div>
        <div className={'md:text-justify text-white px-4 md:px-6'}>
          <h1 className={'md:text-3xl md:text-center text-yellow-500 '}>
            <div className={'md:mb-14'}>Terms And Conditions</div>
          </h1>
          <p className={'md:indent-16 '}>
            Acceptance of Terms:
            By accessing our website or using our services, you agree to comply with these terms and conditions. If you disagree with any part of these terms, you may not access our website or use our services.
            <p className={'py-4'}>
              Intellectual Property Our Services and all associated content, features, and functionality are owned by Quantum Infotech  and are protected by copyright, trademark, patent, and other intellectual property laws
            </p>
            <p className={'py-4'}>
              License and Use: We grant you a non-exclusive, non-transferable license to use our software in accordance with these terms. You agree not to reproduce, distribute, or create derivative works from our software without prior written permission.
            </p>
            <p className={'py-4'}>
              User Responsibilities: You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You agree to use our software for lawful purposes and in compliance with all applicable laws and regulations.
            </p>
            <p className={'py-4'}>
              Third-Party Links and Services Our Services may contain links to third-party websites or services that are not owned or controlled by Quantum Infotech
            </p>
            <p className={'py-4'}>
              Termination: We reserve the right to suspend or terminate your access to the Services at any time and for any reason.
            </p>
            <p className={'py-4'}>
              Changes to Terms: We may update these terms and conditions from time to time. Any changes will be posted on our website, and your continued use of our software constitutes acceptance of the revised terms.
            </p>
            <p className={'py-4'}>
              Severability: If any provision of these terms is found to be invalid or unenforceable, the remaining provisions will continue to be valid and enforceable.
            </p>
            <p className={'py-4'}>
              Contact Information: If you have any questions about these terms and conditions, please contact us at Quantum Infotech.
            </p>
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermsAndCondition;
