import {CloseOutlined, MenuOutlined} from '@ant-design/icons';
import {Button, Drawer, Image, Layout} from 'antd';
import {useEffect, useState} from 'react';
import {NavLink, useLocation} from 'react-router-dom';

import logo from '../../assets/Images/Quantum-Infotech_-_Copy.png';

import {LeftMenu} from './LeftMenu';
import {RightMenu} from './RightMenu';

const NavigationBar = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const location = useLocation();
  useEffect(() => {
    setIsDrawerOpen(false);
  }, [location]);

  return (
    <div className={'sticky top-0 z-50 bg-white shadow border-gray-200'}>
      <Layout>
        <Layout.Header className={'flex items-center justify-around bg-black h-20 p-0'}>
          <div className={'md:w-36 w-32 md:m-10 m-6'}>
            <NavLink exact to={'/AboutUs'} className={'nav-logo'}>
              <Image src={logo} preview={false} className={'mt-8'} alt={'company logo'} />
            </NavLink>
          </div>
          <div className={'flex items-center mr-6 md:mr-0'}>
            <div className={'hidden md:flex'}>
              <LeftMenu mode={'horizontal'} />
            </div>
            <Button
              className={'md:hidden text-gray-100 hover:text-gray-900 focus:outline-none'}
              type={'text'}
              onClick={toggleDrawer}>
              <MenuOutlined />
            </Button>
            <div className={'hidden md:flex'}>
              <RightMenu mode={'horizontal'} />
            </div>
            <div>
              <Drawer
                title={(
                  <Image src={logo} alt={'Brand Logo'}
                    preview={false}
                    style={{width: '100px', height: 'auto'}}
                  />
                )}
                placement={'right'}
                closable={true}
                closeIcon={<CloseOutlined style={{color: 'white'}} />}
                onClose={toggleDrawer}
                open={isDrawerOpen}
                style={{zIndex: 99999, backgroundColor: 'black'}}
              >
                <LeftMenu mode={'inline'} />
                <RightMenu mode={'inline'} />
              </Drawer>
            </div>
          </div>
        </Layout.Header>
      </Layout>
    </div>
  );
};

export default NavigationBar;
