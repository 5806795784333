import {Button, Menu} from 'antd';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

const LeftMenu = ({mode}) => {
  return (
    <nav className={'md:w-[36rem]'}>
      <Menu mode={mode} className={'bg-black text-white'}>
        <Menu.Item key={'home'}>
          <Link to={'/'}>
            <text className={'text-xl font-semibold text-white'}>Home</text>
          </Link>
        </Menu.Item>
        <Menu.Item key={'services'}>
          <Link to={'/services'}>
            <text className={'text-xl font-semibold text-white'}>Services</text>
          </Link>
        </Menu.Item>
        <Menu.Item key={'Career'}>
          <Link to={'/Career'}>
            <text className={'text-xl font-semibold text-white'}>Career</text>
          </Link>
        </Menu.Item>
        <Menu.Item key={'aboutus'}>
          <Link to={'/aboutus'}>
            <text className={'text-xl font-semibold text-white'}>About Us</text>
          </Link>
        </Menu.Item>
        <Menu.Item key={'contactus'} className={'lg:translate-x-32'}>
          <Link to={'/contactus'}>
            <Button className={'bg-orange-500 text-white'}>Contact Us</Button>
          </Link>
        </Menu.Item>
      </Menu>
    </nav>
  );
};

LeftMenu.propTypes = {
  mode: PropTypes.oneOf(['horizontal', 'vertical', 'inline']).isRequired,
};

export default LeftMenu;
