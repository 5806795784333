import {motion} from 'framer-motion';
import React, {useState} from 'react';
import {Link} from 'react-router-dom';
// eslint-disable-next-line import/no-unresolved
import {Autoplay, Navigation} from 'swiper/modules';
// eslint-disable-next-line import/no-unresolved
import {Swiper, SwiperSlide} from 'swiper/react';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/pagination';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/navigation';

import android from '../../assets/Images/Logo/Android.png';
import angular from '../../assets/Images/Logo/Angular.png';
import content from '../../assets/Images/Logo/Content.png';
import dotnet from '../../assets/Images/Logo/dotnet.png';
import ERP from '../../assets/Images/Logo/ERP (1).png';
import IOS from '../../assets/Images/Logo/IOS.png';
import javaScript from '../../assets/Images/Logo/JavaScript.png';
import native from '../../assets/Images/Logo/native.png';
import node from '../../assets/Images/Logo/node.png';
import PHP from '../../assets/Images/Logo/PHP.png';
import reactjs from '../../assets/Images/Logo/reactjs.png';
import UIUX from '../../assets/Images/Logo/UIUX.png';

const projects = [
  {
    title: 'Learning Management System ( LMS )',
    description: (
      <div>
        <p>At Quantum Infotech, we have developed a cutting-edge Learning Management System (LMS) using modern web technologies like React and JavaScript, with a mobile-friendly version built in React Native.</p>
        <p>This versatile platform is designed to streamline online education and training for businesses, educational institutions, and individual learners.</p>
        <strong>Key Features:</strong>
        <ul style={{listStyleType: 'circle', paddingLeft: 24}}>
          <li>Intuitive course creation and management</li>
          <li>Mobile-responsive and app-based learning with React Native</li>
          <li>Customizable user roles for admins, instructors, and learners</li>
          <li>Real-time progress tracking and performance reporting</li>
          <li>Integration with third-party tools for enhanced collaboration</li>
        </ul>
        <p>Our innovative LMS is the perfect solution for organizations seeking to deliver efficient, scalable, and engaging e-learning experiences.</p>
        <p>Visit our portfolio to learn more.</p>
      </div>
    ),
    technologies: [IOS, android, native, javaScript, UIUX, content, android, node ],
    image: require('../../assets/Images/Portfolio/LMS.png'),
  },
  {
    title: 'Sewa Sphere Mobile App',
    description: (
      <div>
        <p>At Quantum Infotech, we have developed a powerful Charity Donation App using the latest web technologies, including React and JavaScript, with a mobile version built in React Native.</p>
        <p>This app provides a seamless platform for individuals and organizations to connect with charitable causes, make donations, and track their contributions.</p>
        <strong>Key Features:</strong>
        <ul style={{listStyleType: 'circle', paddingLeft: 24}}>
          <li>Secure and user-friendly donation process</li>
          <li>Mobile-responsive and app-based functionality with React Native</li>
          <li>Real-time donation tracking and updates</li>
          <li>Integration with secure payment platforms for easy transactions</li>
          <li>Admin dashboard for charity organizations to manage campaigns</li>
        </ul>
      </div>
    ),
    technologies: [IOS, android, native, javaScript, UIUX, content, android, node ],
    tech: require('../../assets/Images/Card_4.png'),
    image: require('../../assets/Images/Portfolio/donation.png'),
  },
  {
    title: 'Digital Marketing Website',
    description: (
      <div>
        <p>At Quantum Infotech, we have developed a modern Digital Website using React, JavaScript, and Tailwind CSS, with a mobile version built in React Native.</p>
        <p>This website is designed to provide a seamless, engaging user experience across various platforms, making it an ideal solution for businesses looking to enhance their digital presence.</p>
        <strong>Key Features:</strong>
        <ul style={{listStyleType: 'circle', paddingLeft: 24}}>
          <li>Responsive and mobile-optimized with React Native</li>
          <li>Sleek and customizable UI built with Tailwind CSS</li>
          <li>Fast, secure, and easy navigation</li>
          <li>Integration with third-party services and APIs</li>
          <li>Admin panel for content management and updates</li>
        </ul>
      </div>
    ),
    technologies: [ UIUX, content, javaScript, node],
    tech: require('../../assets/Images/Card_4.png'),
    image: require('../../assets/Images/Portfolio/imperial.png'),
  },
  {
    title: 'Marketing Team Tracking Mobile App',
    description: (
      <div>
        <p>At Quantum Infotech, we have developed an advanced Marketing Team Tracking App using React, JavaScript, and Tailwind CSS, with a mobile version built in React Native.</p>
        <p>This app is designed to streamline the management and tracking of marketing teams, allowing for real-time insights into performance, task management, and collaboration.</p>
        <strong>Key Features:</strong>
        <ul style={{listStyleType: 'circle', paddingLeft: 24}}>
          <li>Mobile-responsive design with React Native for on-the-go tracking</li>
          <li>Clean and customizable UI using Tailwind CSS</li>
          <li>Real-time tracking of tasks, deadlines, and team performance</li>
          <li>Easy integration with project management tools and CRM systems</li>
          <li>Dashboard for monitoring key performance indicators (KPIs)</li>
        </ul>
      </div>
    ),
    technologies: [android, native, javaScript, UIUX, content, android, node, IOS],
    tech: require('../../assets/Images/Card_4.png'),
    image: require('../../assets/Images/Portfolio/seema_biotech.png'),
  },
  {
    title: 'Agriculture Website',
    description: (
      <div>
        <p>At Quantum Infotech, we have developed a specialized Cow Mats E-commerce Website using React, JavaScript, and Tailwind CSS.</p>
        <p>This platform is designed to provide a seamless online shopping experience for agricultural and dairy farm products, specifically cow mats.</p>
        <strong>Key Features:</strong>
        <ul style={{listStyleType: 'circle', paddingLeft: 24}}>
          <li>Responsive and mobile-friendly design using React Native</li>
          <li>Modern and clean interface styled with Tailwind CSS</li>
          <li>Easy product browsing and secure checkout process</li>
          <li>Integration with payment gateways for smooth transactions</li>
          <li>Admin panel for managing product listings and orders</li>
        </ul>
      </div>
    ),
    technologies: [ UIUX, content, javaScript, node],
    tech: require('../../assets/Images/Card_4.png'),
    image: require('../../assets/Images/Portfolio/goodfeel.png'),
  },
  {
    title: 'Equipment Inventory Mobile App',
    description: (
      <div>
        <p>At Quantum Infotech, we have developed an innovative Agri App using React, JavaScript, and Tailwind CSS, with a mobile version powered by React Native.</p>
        <p>This app is designed to provide farmers and agricultural businesses with a powerful tool to manage their farming activities, access real-time data, and improve productivity.</p>
        <strong>Key Features:</strong>
        <ul style={{listStyleType: 'circle', paddingLeft: 24}}>
          <li>Mobile-responsive and app-based with React Native</li>
          <li>Intuitive user interface styled with Tailwind CSS</li>
          <li>Real-time data tracking for weather, crop management, and more</li>
          <li>Easy-to-navigate design, optimized for rural connectivity</li>
          <li>Integration with third-party tools for advanced functionality</li>
        </ul>
      </div>
    ),
    technologies: [android, native, javaScript, UIUX, content, android, node, IOS],
    tech: require('../../assets/Images/Card_4.png'),
    image: require('../../assets/Images/Portfolio/equipment_inventory.png'),
  },
];

const PortfolioScreen = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleSlideChange = swiper => {
    setActiveIndex(swiper.activeIndex);
  };

  return (
    <div className={'min-h-screen bg-black text-white flex flex-col items-center py-16'}>
      <motion.h2
        className={'text-4xl font-semibold mb-10'}
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        transition={{duration: 1}}
      >
        Portfolio
      </motion.h2>

      <motion.p
        className={'text-2xl font-medium mb-12 text-center'}
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        transition={{delay: 0.5, duration: 1}}
      >
        FROM CHALLENGE TO VICTORY
      </motion.p>

      {/* Swiper Carousel */}
      <Swiper
        modules={[Autoplay, Navigation]}  // Add Autoplay and Navigation modules
        spaceBetween={50}
        slidesPerView={1}
        autoplay={{
          delay: 3000,  // Auto swipe every 3 seconds
          disableOnInteraction: false,  // Keeps autoplay running even after manual interaction
        }}
        onSlideChange={handleSlideChange}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        loop={true}  // Enable looping
        className={'w-full max-w-5xl'}
      >
        {projects.map((project, index) => (
          <SwiperSlide key={index}>
            <div className={'grid grid-cols-1 md:grid-cols-2 gap-8 p-6'}>
              {/* Project Image */}
              <motion.div
                className={'overflow-hidden rounded-lg shadow-lg'}
                whileHover={{scale: 1.05}}
                transition={{duration: 0.5}}
              >
                <img
                  src={project.image}
                  alt={project.title}
                  className={'w-full h-full object-cover'}
                />
              </motion.div>

              {/* Project Details */}
              <div className={'flex flex-col justify-between'}>
                <div>
                  <h3 className={'text-3xl font-bold mb-4'}>{project.title}</h3>
                  <p className={'text-gray-400 mb-6'}>{project.description}</p>
                  <div className={'flex space-x-4 mb-8'}>
                    {project.technologies.map((tech, i) => (
                      <motion.div
                        key={i}
                        whileHover={{scale: 1.2}}
                        transition={{duration: 0.3}}
                      >
                        <img
                          src={tech}
                          alt={tech}
                          className={'w-10 h-10 object-contain'}
                        />
                      </motion.div>
                    ))}
                  </div>
                </div>

                <Link to={'/contactus'}>
                  <motion.button
                    className={'bg-indigo-600 py-3 px-8 rounded-lg font-medium text-white hover:bg-indigo-500'}
                    whileHover={{scale: 1.05}}
                    whileTap={{scale: 0.95}}
                    transition={{duration: 0.3}}>
                  To know more contact us →
                  </motion.button>
                </Link>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      {/* Navigation Arrows */}

      <div>
        <button
          className={'text-white text-lg opacity-30'}>
            Swipe to know more →
        </button>
      </div>
    </div>

  );
};

export default PortfolioScreen;
