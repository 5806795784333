import {motion, useInView} from 'framer-motion';
import React, {useRef} from 'react';
import {Link} from 'react-router-dom';

import image1 from '../../assets/Images/image1.jpeg';

const QIAboutUs = () => {
  const ref = useRef(null);

  const isInView = useInView(ref, {once: true});

  const stats = [
    {number: '120+', label: 'Projects Completed'},
    {number: '3+', label: 'Successful Years'},
    {number: '96%', label: 'Client Retention'},
    {number: '6+', label: 'Countries'},
  ];

  return (
    <div>
      <div className={'bg-black text-white flex flex-col justify-center items-center min-h-screen p-5 md:p-10'}
        ref={ref}>
        <div>
          <h1 className={'bg-black text-white text-2xl lg:text-[33px] text-center p-5 md:p-10'}>
            <div className={'text-center text-xl opacity-30 p-5 pb-2'}> who we are</div>
            <span className={'uppercase font-bold'}>Empowering Tomorrow’s Technology Today.</span>
          </h1>
        </div>
        <div className={'max-w-7xl mx-auto flex flex-col md:flex-row items-center gap-5 md:gap-10'}>
          <motion.div
            initial={{opacity: 0, x: -20}}
            animate={{opacity: isInView ? 1 : 0, x: isInView ? 0 : -20}}
            transition={{duration: 0.8}}
            className={'md:w-1/2'}
          >
            <img
              src={image1}
              alt={'Team working together'}
              className={'rounded-lg shadow-lg w-full h-auto'}
            />
          </motion.div>
          <motion.div
            initial={{opacity: 0, x: 20}}
            animate={{opacity: isInView ? 1 : 0, x: isInView ? 0 : 20}}
            transition={{duration: 0.8}}
            className={'md:w-1/2 space-y-4'}
          >
            <h2 className={'text-2xl md:text-3xl lg:text-4xl '}>
              About <span className={'text-orange-500'}>Quantum</span>
            </h2>
            <ul style={{listStyleType: 'circle'}}>
              <li className={'mb-2'}>
                <strong>Innovative Team:</strong> Quantum Infotech is a team of dedicated innovators, strategists, and
                technology enthusiasts focused on transforming businesses with cutting-edge software solutions.
              </li>
              <li className={'mb-2'}>
                <strong>Extensive Experience:</strong> With over 6 years of experience in full-stack development,
                e-commerce solutions, and mobile app development, we specialize in creating high-performance products
                that drive business growth.
              </li>
              <li className={'mb-2'}>
                <strong>Wide Range of Services:</strong> Our expertise includes:
                <ul style={{listStyleType: 'disc', paddingLeft: 18}}>
                  <li>Web Development</li>
                  <li>Mobile App Development</li>
                  <li>ERP Systems</li>
                  <li>UI/UX Design</li>
                  <li>Digital Marketing</li>
                </ul>
              </li>
              <li>
                <strong>Technology Focus:</strong> We leverage advanced technologies like React, JavaScript, Node.js,
                and cloud-based solutions to build robust and scalable digital experiences.
              </li>
            </ul>
            <Link to={'/aboutus'}>
              <button
                className={'bg-transparent text-white px-4 py-2 md:px-6 md:py-3 rounded-md shadow-md mt-4 hover:border-orange-500 border-2'}>
                Who We Are →
              </button>
            </Link>
          </motion.div>
        </div>
      </div>

      <div className={'bg-black text-white py-10'} ref={ref}>
        <div className={'max-w-7xl mx-auto flex flex-wrap justify-center items-center px-5'}>
          {stats.map((stat, index) => (
            <motion.div
              key={index}
              initial={{opacity: 0, y: 50}}
              animate={{opacity: isInView ? 1 : 0, y: isInView ? 0 : 50}}
              transition={{duration: 0.5, delay: index * 0.2}}
              className={'text-center mx-5 my-5 md:mx-10'}
            >
              <h3 className={'text-3xl md:text-4xl font-bold'}>{stat.number}</h3>
              <p className={'text-gray-400 mt-2'}>{stat.label}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default QIAboutUs;
