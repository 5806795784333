import {Image} from 'antd';
import {motion} from 'framer-motion';
import {useEffect, useState} from 'react';

import office from '../../../assets/Images/4.jpg';

const PartFour = () => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const id = setInterval(() => {
      setIndex(state => {
        return state + 1;
      });
    }, 2000);
    return () => clearInterval(id);
  }, []);

  return (
    <div
      className={'flex flex-row justify-center mt-9'}>
      <div
        className={'flex md:mr-0 lg:flex flex-col justify-center lg:flex-row items-center'}>
        <div className={'md:mr-12 lg:-translate-x-0'}>
          <motion.div
            initial={{
              opacity: 0,
              x: index % 2 === 0 ? -30 : 30,
            }}
            whileInView={{
              opacity: 1,
              x: 0, // Slide in to its original position
              //rotate: [0, 360], // Add rolling effect from 0 to 360 degrees
              transition: {
                duration: 2, // Animation duration
                ease: 'easeInOut', // Easing function for smooth rolling
              },
            }}
            viewport={{once: false}}
          >
            <h1 className={'text-sm py-2 lg:text-3xl text-yellow-500 font-bold'}> 2025-2026 </h1>
          </motion.div>
        </div>
        <div className={'w-32 h-32 lg:w-52 lg:h-52  lg:-translate-x-8 z-10'}>
          <motion.div
            whileHover={{scale: 1.2}}
            whileTap={{scale: 0.8}}
          >
            <Image src={office}
              className={'border-header-color border-4 border-yellow-500 rounded-3xl'}
              preview={false}
            />
          </motion.div>
        </div>
      </div>
      <motion.div
        initial={{
          opacity: 0,
          x: 30, // Start position off-screen to the right
        }}
        whileInView={{
          opacity: 1,
          x: 0, // Slide in to its original position
          transition: {
            duration: 3, // Animation duration
          },
        }}
        viewport={{once: false}}
      >
        <div
          className={'flex flex-col justify-center text-white border rounded-3xl w-52 lg:w-96 lg:aspect-square p-1 lg:p-12 lg:-translate-x-16 lg:-translate-y-0'}>
          <div className={'pl-5 p-6 lg:p-0 lg:pl-9'}>
            <h1 className={'text-xl lg:text-3xl'}> Phase Two Expansion </h1>
            <p className={'text-sm w-44 lg:w-60'}> the suspense heightens as we stand at the cusp of the future.
              rooted in the dynamic
              landscape of technology and fueled by the collective expertise of our team, our
              startup aspires to transcend boundaries. our dream is not merely to go big but to
              redefine whats possible in the realm of software development. the journey ahead
              promises untold adventures and the realization of aspirations yet to unfold!
            </p>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default PartFour;
