import {DollarOutlined, SettingOutlined, UserOutlined} from '@ant-design/icons';
import {Card, Divider} from 'antd';
import {motion} from 'framer-motion';
import React from 'react';

import about from '../../../assets/Images/About.png';
import Footer from '../../Footer/Footer';
import {OurJourney} from '../index';

const cardVariant = {
  hidden: {opacity: 0, y: 50},
  visible: {opacity: 1, y: 0},
};

const AboutUs = () => {
  return(
    <div>
      <div style={{
        backgroundImage: window.innerWidth < 768 ? `url(${about})` : `url(${about})`,
        height: '50vh',
        backgroundSize: 'cover',
        backgroundBlendMode: 'hard-light',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}>
        <div className={'md:absolute md:pt-0 pt-64 md:right-20 md:mt-20 md:w-96'}>
          <h1 className={'text-4xl font-bold md:text-6xl text-yellow-500'}>About Us</h1>
          <p className={'text-white text-3xl'}>
            <h1>We innovate today</h1>
            <h1>to shape a brighter</h1>
            <h1>tomorrow.</h1>
          </p>
        </div>
      </div>

      <OurJourney />

      <div
        className={'bg-zinc-950 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-2 lg:gap-20 px-6 md:px-32 lg:p-20 justify-center xl:px-32 min-h-2'}>

        {/* vision */}
        <motion.div
          className={'bg-gray-800 p-10 rounded-lg h-full w-80 lg:w-80 xl:w-96 shadow-md text-white'}
          variants={cardVariant}
          initial={'hidden'}
          animate={'visible'}
          transition={{delay: 0.1, duration: 0.8}}
        >
          <h3 className={'text-xl font-semibold'}>Vision</h3>
          <div className={'w-36'}><Divider className={'bg-white'} /></div>
          <p className={'mt-2 text-sm lg:text-md'}>
            Our vision is to become the top trusted software innovation partner by enabling businesses to reimagine
            possibilities and succeed in the digital landscape.<br />
            We aim to achieve this by 2025 through 3 key focus
            areas: <br />
            1.Deliver 10x growth and maintain over 95% client retention rate <br />
            2.Expand our talent pool to over 100 experts across emerging technologies <br />
            3.Establish centers of excellence dedicated to our core service offerings
          </p>
        </motion.div>

        {/* mission */}
        <motion.div
          className={'bg-gray-800 p-10 rounded-lg shadow-md h-full w-80 lg:w-80 xl:w-96 text-white'}
          variants={cardVariant}
          initial={'hidden'}
          animate={'visible'}
          transition={{delay: 0.3, duration: 0.8}}
        >
          <h3 className={'text-xl font-semibold'}>Mission</h3>
          <div className={'w-36'}><Divider className={'bg-white'} /></div>
          <p className={'mt-2 text-sm lg:text-md'}>
            Our mission is to help businesses keep up with the pace of technology by building custom mobile and web
            applications tailored to their
            unique needs. We are committed to understanding each client is specific goals and challenges so that we
            can provide cost-effective, scalable
            technical solutions that drive meaningful business outcomes. Our approach focuses on communication,
            creativity, and community in order to foster
            long-lasting partnerships with our clients. We measure our success through our client’s success.
          </p>
        </motion.div>

        {/* goal */}
        <motion.div
          className={'bg-gray-800 p-6 rounded-lg shadow-md h-full w-80 lg:w-80 xl:w-96 text-white'}
          variants={cardVariant}
          initial={'hidden'}
          animate={'visible'}
          transition={{delay: 0.5, duration: 0.8}}
        >
          <h3 className={'text-xl font-semibold'}>Goals</h3>
          <div className={'w-36'}><Divider className={'bg-white'} /></div>
          <p className={'mt-2 text-sm lg:text-md'}>
            <span className={'inline-block w-2 h-2 bg-gray-400 rounded-full lg:mr-2'} />
            Achieve market leadership in our industry verticals including fintech, healthtech, and mobile
            commerce <br />

            <span className={'inline-block w-2 h-2 bg-gray-400 rounded-full lg:mr-2'} />
            Ongoing mentorship programs, internal job postings, clear promotion tracks, and opportunities to
            transition across technologies make
            us the top choice for ambitious talent.<br />

            <span className={'inline-block w-2 h-2 bg-gray-400 rounded-full lg:mr-2'} />
            Become the most trusted technology partner for 99+ enterprises nationally through 95%+ client
            retention
            rates driven by a customer-centric delivery model that emphasizes transparent communication and
            predictable outcomes.<br />

            <SettingOutlined className={'text-lg mr-2 text-gray-400 mt-2'} />
            Achieve double digit annual growth through consistent innovation and high client retention<br />

            <DollarOutlined className={'text-lg mr-2 text-gray-400'} />
            Maintain consistent growth momentum of at least 2.5x YoY<br />

            <UserOutlined className={'text-lg mr-2 text-gray-400'} />
            We actively support employee-led CSR initiatives in local communities and partner with organizations
            focused on technology for social good.
          </p>
        </motion.div>
      </div>

      <Footer />

    </div>
  );
};

export default AboutUs;
