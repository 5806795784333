import {Button, ConfigProvider, Image} from 'antd';
import {useEffect} from 'react';

import CareerBg1 from '../../assets/Images/CareerBg[1].png';
import CareerImage from '../../assets/Images/CareerImage.svg';
import {Footer} from '../Footer';

const Career = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return(
    <div>
      <div className={'flex justify-center bg-zinc-950'}>
        <div className={'text-white justify-center w-full'}>
          <div style={{
            backgroundImage: window.innerWidth < 768 ? `url(${CareerBg1})` : `url(${CareerBg1})`,
            height: '50vh',
            backgroundSize: 'cover',
            backgroundBlendMode: 'hard-light',
            backgroundPosition: 'top',
            backgroundRepeat: 'no-repeat',
          }}>
            <div className={'pt-64 md:pt-0 md:absolute md:right-20 md:mt-20 md:w-96 pl-4 lg:pl-20'}>
              <h1 className={'text-4xl font-bold md:text-6xl text-yellow-500'}>Career</h1>
              <p className={'text-white text-3xl '}>
                <h1>Step into a world of possibilities</h1>
              </p>
            </div>
          </div>
          <div className={'flex py-6 pt-16 md:px-52 md:py-8 flex-col md:flex-row'}>
            <div className={'px-6 md:py-28 flex justify-center  md:justify-start '}>
              <Image src={CareerImage} preview={false} width={'50vh'} />
            </div>
            <div className={'md:text-justify px-4 md:px-6 w-full '}>
              <h1 className={'text-3xl text-center text-yellow-500 py-6'}>AT QUANTUM INFOTECH ! </h1>
              <p className={'indent-16 py-4 '}>
                We are driven by a mission to innovate and deliver cutting-edge software
                solutions that transform industries and enhance lives. Our vision is to lead the tech world with
                creativity and excellence, underpinned by our core values of integrity, collaboration, and customer
                focus.
              </p>
              <p className={'indent-16 py-4'}>Our career opportunities span various roles in software development,
                project management, Digital marketing and more.To apply, simply follow the instructions provided on our
                job postings page, which may include submitting your resume through an online form or directly via
                email.
              </p>
              <p className={'indent-16 py-4'}>We believe that a positive work environment is key to our success. Our
                offices are designed to be collaborative spaces where creativity can flourish. We regularly host
                team-building activities and social events to strengthen our community and create a fun, engaging
                workplace. Don&apos;t just take our word for it – hear from our current employees through testimonials
                and video stories, where they share their experiences and the reasons they love working at Quantum
                Infotech !
              </p>
              <p className={'indent-16 pt-4'}>Join us at Quantum Infotech ! and be part of a forward-thinking team where
                your contributions are valued, and your career can thrive. Visit our careers page to explore current
                openings and take the first step toward a rewarding career with us.
              </p>
            </div>
          </div>
          <div className={'flex py-8 pt-16 md:px-52 md:py-8 flex-col md:flex-row justify-center'}>
            <div className={'w-full px-4'}>
              <h1 className={'text-3xl py-4'}> Our Work Culture</h1>
              <p className={'flex flex-row py-1'}>
                <p className={'font-bold pr-2'}>1.</p>
                We embrace creativity and out-of-the-box thinking to deliver cutting-edge solutions.
              </p>
              <p className={' flex flex-row py-1'}>
                <p className={'font-bold pr-2'}>2.</p>
                We foster a team environment where everyone&apos;s input is valued, leading to the best outcomes.
              </p>
              <p className={'flex flex-row py-1'}>
                <p className={'font-bold pr-2'}>3.</p>
                We promote ongoing professional development and skill enhancement to keep pace with the evolving tech industry.
              </p>
            </div>
            <div className={'w-full px-4'}>
              <h1 className={'text-3xl py-4'}>Why Join Our Team?</h1>
              <p>
                At Quantum Infotech , we provide a creative and collaborative environment with top-notch facilities. You&apos;ll grow through regular training and career opportunities while enjoying flexible work hours, remote options, and great health benefits. Work on meaningful projects and get involved in community initiatives to make a real difference. Join us and be a part of something impactful!
              </p>
            </div>
          </div>
          <div className={'flex justify-center flex-col pt-12 px-8 md:px-0'}>
            <h1 className={'flex justify-center text-3xl'}>Career Opportunities</h1>
            <h1 className={'flex justify-center text-center text-3xl '}>
              Apply here to start your amazing journey!
            </h1>
            <div className={'flex justify-center pt-10'}>
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: '#fbbf24', // Amber 400 for initial background
                    colorPrimaryHover: '#16a34a', // Green 600 for hover background
                    colorText: '#000000', // Black text initially
                    colorTextHover: '#ffffff', // White text on hover
                  },
                  components: {
                    Button: {
                      colorBgContainer: '#fbbf24', // Initial background color
                      colorBgHover: '#ffffff', // Green background on hover
                      colorText: '#000000', // Initial text color
                      colorTextHover: '#ffffff', // White text on hover
                    },
                  }}}>
                <Button className={' w-28 h-10 bg-opacity-45 text-center text-black rounded-lg'} type={'primary'}>
                  <a href={'http://linkedin.com/company/quantum-infotechs'} target={'_blank'} rel={'noreferrer'}>CLICK HERE</a>
                </Button>
              </ConfigProvider>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Career;
