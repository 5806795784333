import {Image} from 'antd';
import {useEffect} from 'react';

import PrivacyPolicyMain from '../../../assets/Images/PrivacyPolicy.svg';
import PrivacyPolicyBanner from '../../../assets/Images/PrivacyPolicyBanner.png';
import {Footer} from '../index';

const privacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return(
    <div className={'bg-zinc-950'}>
      <div style={{
        backgroundImage: window.innerWidth < 768 ? `url(${PrivacyPolicyBanner})` : `url(${PrivacyPolicyBanner})`,
        height: '50vh',
        backgroundSize: 'cover',
        backgroundBlendMode: 'hard-light',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}>
        <div className={'absolute md:right-20 md:w-40 mt-20 md:ml-30'}>
          <h1 className={'text-4xl md:font-bold md:text-6xl text-yellow-500'}>Privacy policy</h1>
        </div>
      </div>
      <div className={'flex md:px-52 md:py-8 flex-col md:flex-row'}>
        <div className={'px-6 md:py-28 md:flex justify-center md:justify-start '}>
          <Image src={PrivacyPolicyMain}  preview={false} height={'70vh'} width={'45vh'} className={'flex items-center justify-center'}  />
        </div>
        <div className={'md:text-justify text-white px-4 md:px-6'}>
          <h1 className={'md:text-3xl md:text-center text-yellow-500 '}>
            <div className={'md:mb-14'}> Privacy policy </div>
          </h1>
          <p className={'md:indent-16'}>
            Collection of Personal Data shall be resorted to only to the extent that it may be necessary for the
            technical and commercial operation of its activities;
            Users shall be informed of any such collection and shall also be informed of the specific purpose
            for which it is being collected not later than the moment of collection;
            The Personal Data collected shall be used fairly and lawfully for the specified purposes and only
            for these purposes
            <p className={'md:indent-16 py-4'}>
              Collection of Data and Information:
              Users that wish to avail themselves of that part of the Services which requires registration
              and who submit an online application shall be required to provide the Company with particular
              information in the form of completing certain fields which the Company may deem necessary for
              the provision of the Services.
            </p>
            <p className={'indent-16 py-4'}>
              Access and Control to Personal Data:
              view the Personal Data held by the Company on the particular User request that any Personal Data that is inaccurate, irrelevant or out of date be amended or deleted; and choose to stop using the Services and have his or her Personal Data deleted, if such deletion is permissible by law and/or will not prejudice the Company’s position at law Security
            </p>
            <p className={'indent-16 py-4'}>
              Security Measures:
              We implement robust security measures, including encryption and access controls, to protect your data from unauthorized access, alteration, or disclosure. Our team continuously reviews and enhances these measures.
            </p>
            <p className={'indent-16 py-4'}>
              Your Rights: You have the right to access, update, or delete your personal information at any time. If you wish to exercise these rights or have any privacy-related concerns, please contact our support team.
            </p>
            <p className={'indent-16 py-4'}>
              Policy Updates: We may update this privacy policy periodically to reflect changes in our practices or legal requirements. We will notify you of any significant updates and encourage you to review the policy regularly.
            </p>
            <p className={'indent-16 py-4'}>
              Data Usage: We use your personal information to provide and improve our services, process transactions, respond to inquiries, and send relevant updates. We may also use aggregated data for analytical purposes to better understand user needs and preferences.
            </p>
            <p className={'py-4'}>
              Thank you! for trusting Quantum Infotech with your personal information. We are dedicated to safeguarding your privacy and providing a secure experience.
            </p>
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default privacyPolicy;
