import {Button, Image} from 'antd';
import {AnimatePresence, motion} from 'framer-motion';
import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

import Content from '../../assets/Images/content.png';
import Digital from '../../assets/Images/digital.png';
import CRMApp from '../../assets/Images/ERP.png';
import MobileApp from '../../assets/Images/mobileapp.png';
import UI from '../../assets/Images/uiuxview.png';
import WebApp from '../../assets/Images/webdev.png';

const data = [
  {
    id: 1,
    content: 'Mobile App Development',
  },
  {
    id: 2,
    content: 'Web Development',
  },
  {
    id: 3,
    content: 'ERP Software',
  },
  {
    id: 4,
    content: 'UI/UX Design',
  },
  {
    id: 5,
    content: 'Digital Marketing',
  },
  {
    id: 6,
    content: 'Content Writing',
  },
];

const QIProvide = () => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const id = setInterval(() => {
      setIndex(state => {
        if (state >= data.length - 1) {return 0;}
        return state + 1;
      });
    }, 2000);
    return () => clearInterval(id);
  }, []);

  return(
    <div className={'md:p-24 pt-20 bg-zinc-950 text-white'}>
      <div className={'md:mb-20 mb-10 p-4'}>
        <div>
          <AnimatePresence>
            <motion.div className={'md:flex font-bold key={items[index].id'}
              initial={{y: 20, opacity: 0}}
              animate={{y: 0, opacity: 1}}
              exit={{y: -20, opacity: 0}}
              transition={{ease: 'easeInOut'}}
            >
              <span className={'text-2xl md:text-4xl'}>What Quantum can Provide for you &nbsp;</span>
              <span className={'text-2xl md:text-4xl text-yellow-500'}>{data[index].content}</span>
            </motion.div>
          </AnimatePresence>
        </div>
        <div className={'mt-3'}>
          <h2>&ldquo;elevating excellence, tailoring solutions: our services, your pathway to seamless success&rdquo;</h2>
        </div>
      </div>

      <div className={'md:flex justify-center'}>
        {/*1 Service*/}
        <div className={'flex md:block justify-around pb-4'}>
          <motion.div
            initial={{
              opacity: 0,
              // if odd index card,slide from right instead of left
              x: index % 2 === 5 ? 30 : -30,
            }}
            whileInView={{
              opacity: 1,
              x: 0, // Slide in to its original position
              transition: {
                duration: 3, // Animation duration
              },
            }}
            viewport={{once: false}}
            className={'md:flex md:flex-row pl-16 md:p-0'}>
            <div className={'absolute md:static w-32 h-32 md:w-48 md:h-48 -translate-x-16 md:translate-x-12 md:-translate-y-10'}>
              <Image src={MobileApp} preview={false}
                className={'rounded-3xl rounded-tr-none md:rounded-tr-3xl border-4 border-yellow-400'} />
            </div>
            <div
              className={'border-2 border-gray-400 md:p-16 w-72 h-80 md:w-[23rem] md:h-[20rem] rounded-2xl md:rounded-3xl overflow-hidden pl-16 pr-2 md:pr-6 pt-4'}>
              <h1 className={'text-2xl'}>Mobile App Development</h1>
              <p className={'text-sm'}>
                At Quantum Infotech, we redefine possibilities. Our team of
                passionate experts specializes in custom software applications, web and mobile app
                development, and robust enterprise solutions. We thrive on pushing boundaries and
                transforming challenges into opportunities.
                <div className={'float-end'} key={index}>
                  <Link to={'/details/1'}>
                    <Button className={'relative mt-5 md:mt-4 inline-flex float-end border-yellow-500 overflow-hidden p-[1px] focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2 focus:ring-offset-yellow-500'}>
                      <span className={'absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-yellow-900'} />
                      <span className={'inline-flex h-full w-full cursor-pointer items-center justify-center bg-slate-950 px-3 py-1 text-sm font-medium text-white backdrop-blur-3xl'}>
                        Read More
                      </span>
                    </Button>
                  </Link>
                </div>
              </p>
            </div>
          </motion.div>
        </div>

        {/*2 Service*/}
        <div
          className={'flex md:block justify-around md:translate-y-24'}>
          <motion.div
            initial={{
              opacity: 0,
              // if odd index card,slide from right instead of left
              x: index % 2 === 0 ? 30 : -30,
            }}
            whileInView={{
              opacity: 1,
              x: 0, // Slide in to its original position
              transition: {
                duration: 3, // Animation duration
              },
            }}
            viewport={{once: false}}
            className={'md:flex md:flex-row pl-16'}>
            <div
              className={'absolute md:static w-32 h-32 md:w-48 md:h-48 -translate-x-16 md:translate-x-12 md:-translate-y-10'}>
              <Image src={WebApp} preview={false}
                className={'rounded-3xl rounded-tr-none md:rounded-tr-3xl border-4 border-yellow-400'} />
            </div>
            <div
              className={'border-2 border-gray-400 md:p-16 w-72 h-80 md:w-[23rem] md:h-[20rem] rounded-2xl md:rounded-3xl overflow-hidden pl-16 pr-2 md:pr-6 pt-4'}>
              <h1 className={'text-2xl'}>Web Development</h1>
              <p className={'text-sm pt-3'}>
                Overview of your website development services, including frontend and backend technologies. Examples of
                visually appealing and functional websites you&apos;ve created. Information about responsive design and
                user
                experience.
              </p>
              <Link to={'/details/2'}>
                <Button className={'relative mt-10 md:mt-4 inline-flex float-end border-yellow-500 overflow-hidden p-[1px] focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2 focus:ring-offset-yellow-500'}>
                  <span className={'absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-yellow-900'} />
                  <span className={'inline-flex h-full w-full cursor-pointer items-center justify-center bg-slate-950 px-3 py-1 text-sm font-medium text-white backdrop-blur-3xl'}>
                  Read More
                  </span>
                </Button>
              </Link>
            </div>
          </motion.div>
        </div>
      </div>

      <div className={'md:flex pt-4 md:pt-20 justify-center'}>
        {/*3 Service*/}
        <div className={'flex md:block justify-around pb-4'}>
          <motion.div
            initial={{
              opacity: 0,
              // if odd index card,slide from right instead of left
              x: index % 2 === 5 ? 30 : -30,
            }}
            whileInView={{
              opacity: 1,
              x: 0, // Slide in to its original position
              transition: {
                duration: 3, // Animation duration
              },
            }}
            viewport={{once: false}}
            className={'md:flex md:flex-row pl-16 md:p-0'}>
            <div
              className={'absolute md:static w-32 h-32 md:w-48 md:h-48 -translate-x-16 md:translate-x-12 md:-translate-y-10'}>
              <Image src={CRMApp} preview={false}
                className={'rounded-3xl rounded-tr-none md:rounded-tr-3xl border-4 border-yellow-400'} />
            </div>
            <div
              className={'border-2 border-gray-400 md:p-16 w-72 h-80 md:w-[23rem] md:h-[20rem] rounded-2xl md:rounded-3xl overflow-hidden pl-16 pr-2 md:pr-6 pt-2'}>
              <h1 className={'text-2xl'}>ERP Software</h1>
              <p className={'text-sm'}>
                Software ERP is an integrated suite of applications designed to streamline and automate various business processes across an organization. ERP systems consolidate data and processes from different departments such as finance, human resources, sales, inventory, and procurement into a single unified system.
              </p>
              <div className={'float-end m-4'} key={index}>
                <Link to={'/details/3'}>
                  <Button className={'relative mt-1 md:mt-1 inline-flex float-end border-yellow-500 overflow-hidden p-[1px] focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2 focus:ring-offset-yellow-500'}>
                    <span className={'absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-yellow-900'} />
                    <span className={'inline-flex h-full w-full cursor-pointer items-center justify-center bg-slate-950 px-3 py-1 text-sm font-medium text-white backdrop-blur-3xl'}>
                  Read More
                    </span>
                  </Button>
                </Link>
              </div>
            </div>
          </motion.div>
        </div>

        {/*4 Service*/}
        <div className={'flex md:block justify-around md:translate-y-24'}>
          <motion.div
            initial={{
              opacity: 0,
              // if odd index card,slide from right instead of left
              x: index % 2 === 0 ? 30 : -30,
            }}
            whileInView={{
              opacity: 1,
              x: 0, // Slide in to its original position
              transition: {
                duration: 3, // Animation duration
              },
            }}
            viewport={{once: false}}
            className={'md:flex md:flex-row pl-16'}>
            <div
              className={'absolute md:static w-32 h-32 md:w-48 md:h-48 -translate-x-16 md:translate-x-12 md:-translate-y-10'}>
              <Image src={UI} preview={false}
                className={'rounded-3xl rounded-tr-none md:rounded-tr-3xl border-4 border-yellow-400'} />
            </div>
            <div
              className={'border-2 border-gray-400 md:p-16 w-72 h-80 md:w-[23rem] md:h-[20rem] rounded-2xl md:rounded-3xl overflow-hidden pl-16 pr-2 md:pr-6 pt-4'}>
              <h1 className={'text-2xl'}>UI/UX Design</h1>
              <p className={'text-sm'}>
                Immerse your brand in captivating visuals and seamless experiences with our cutting-edge animation
                designs. Leveraging state-of-the-art technologies,
                our team transforms concepts into dynamic, interactive narratives, using 2D, 3D, and motion graphics to
                breathe life into your ideas. Explore the fusion
              </p>
              <div className={'float-end'} key={index}>
                <Link to={'/details/4'}>
                  <Button className={'relative mt-2 md:mt-4 inline-flex float-end border-yellow-500 overflow-hidden p-[1px] focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2 focus:ring-offset-yellow-500'}>
                    <span className={'absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-yellow-900'} />
                    <span className={'inline-flex h-full w-full cursor-pointer items-center justify-center bg-slate-950 px-3 py-1 text-sm font-medium text-white backdrop-blur-3xl'}>
                      Read More
                    </span>
                  </Button>
                </Link>
              </div>
            </div>
          </motion.div>
        </div>
      </div>

      <div className={'md:flex pt-4 md:pt-20 justify-center'}>
        {/*5 Service*/}
        <motion.div
          initial={{
            opacity: 0,
            // if odd index card,slide from right instead of left
            x: index % 2 === 5 ? 20 : -20,
          }}
          whileInView={{
            opacity: 1,
            x: 0, // Slide in to its original position
            transition: {
              duration: 3, // Animation duration
            },
          }}
          viewport={{once: false}}
          className={'flex md:block justify-around pb-4'}>
          <div className={'md:flex md:flex-row pl-16 md:p-0'}>
            <div
              className={'absolute md:static w-32 h-32 md:w-48 md:h-48 -translate-x-16 md:translate-x-12 md:-translate-y-10'}>
              <Image src={Digital} preview={false}
                className={'rounded-3xl rounded-tr-none md:rounded-tr-3xl border-4 border-yellow-400'} />
            </div>
            <div
              className={'border-2 border-gray-400 md:p-16 w-72 h-80 md:w-[23rem] md:h-[20rem] rounded-2xl md:rounded-3xl overflow-hidden pl-16 pr-2 md:pr-6 pt-4'}>
              <h1 className={'text-2xl'}>Digital Marketing</h1>
              <p className={'text-sm pt-3'}>
                At Quantum Infotech, we redefine possibilities. Our team of
                passionate experts specializes in custom software applications, web and mobile app
                development, and robust enterprise solutions. We thrive on pushing boundaries and
                transforming challenges into opportunities.
              </p>
              <div className={'float-end'} key={index}>
                <Link to={'/details/5'}>
                  <Button className={'relative mt-5 md:mt-4 inline-flex float-end border-yellow-500 overflow-hidden p-[1px] focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2 focus:ring-offset-yellow-500'}>
                    <span className={'absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-yellow-900'} />
                    <span className={'inline-flex h-full w-full cursor-pointer items-center justify-center bg-slate-950 px-3 py-1 text-sm font-medium text-white backdrop-blur-3xl'}>
                  Read More
                    </span>
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </motion.div>

        {/*6 Service*/}
        <div className={'flex md:block justify-around md:translate-y-24'}>
          <motion.div
            initial={{
              opacity: 0,
              // if odd index card,slide from right instead of left
              x: index % 2 === 0 ? 30 : -30,
            }}
            whileInView={{
              opacity: 1,
              x: 0, // Slide in to its original position
              transition: {
                duration: 3, // Animation duration
              },
            }}
            viewport={{once: false}}
            className={'md:flex md:flex-row pl-16'}>
            <div
              className={'absolute md:static w-32 h-32 md:w-48 md:h-48 -translate-x-16 md:translate-x-12 md:-translate-y-10'}>
              <Image src={Content} preview={false}
                className={'rounded-3xl rounded-tr-none md:rounded-tr-3xl border-4 border-yellow-400'} />
            </div>
            <div
              className={'border-2 border-gray-400 md:p-16 w-72 h-80 md:w-[23rem] md:h-[20rem] rounded-2xl md:rounded-3xl overflow-hidden pl-16 pr-2 md:pr-6 pt-4'}>
              <h1 className={'text-2xl'}>Content Writing</h1>
              <p className={'text-sm pt-3'}>
                At Quantum Infotech, we redefine possibilities. Our team of
                passionate experts specializes in custom software applications, web and mobile app
                development, and robust enterprise solutions. We thrive on pushing boundaries and
                transforming challenges into opportunities.
              </p>
              <div className={'float-end'} key={index}>
                <Link to={'/details/6'}>
                  <Button className={'relative mt-1 md:mt-4 inline-flex float-end border-yellow-500 overflow-hidden p-[1px] focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2 focus:ring-offset-yellow-500'}>
                    <span className={'absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-yellow-900'} />
                    <span className={'inline-flex h-full w-full cursor-pointer items-center justify-center bg-slate-950 px-3 py-1 text-sm font-medium text-white backdrop-blur-3xl'}>
                  Read More
                    </span>
                  </Button>
                </Link>
              </div>
            </div>
          </motion.div>
        </div>
      </div>

      <div className={'md:ml-40'}>
        <div className={'p-4 md:p-6 md:w-[39rem] justify-around'}>
          <h1 className={'md:text-2xl text-xl mb-2'}>Other Services</h1>
          <p className={'text-sm'}>Revolutionize your operations with our diverse range of software solutions. From
            customized applications
            to cutting-edge innovations, we leverage the latest technologies to address your unique needs. Experience
          seamless integration, enhanced efficiency, and a transformative digital journey tailored to propel your business
          forward in the dynamic landscape of modern technology.
          </p>
        </div>
      </div>
    </div>
  );
};

export default QIProvide;
