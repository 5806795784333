import {Button, ConfigProvider, Image, Input} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import {useEffect} from 'react';

import contactus from '../../assets/Images/contactus.svg';
import ContactUsbanner from '../../assets/Images/ContactUsBanner.png';
import ContactUsMobileView from '../../assets/Images/ContactUSMobileView.png';
import {Footer} from '../Footer';

const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={'text-white justify-center w-full bg-zinc-950'}>
      <div style={{
        backgroundImage: window.innerWidth < 768 ? `url(${ContactUsMobileView})` : `url(${ContactUsbanner})`,
        height: '50vh',
        backgroundSize: 'cover',
        backgroundBlendMode: 'hard-light',
        backgroundPosition: '-1px 0%',
        backgroundRepeat: 'no-repeat',
      }}>
        <div className={'pt-64 md:pt-0 md:absolute md:right-20 md:mt-20 md:w-96 pl-4 md:pl-14'}>
          <h1 className={'text-4xl font-bold md:text-6xl text-yellow-500'}>Contact Us</h1>
          <p className={'text-white text-3xl'}>
            <h1>Get in touch and </h1>
            <h1>let&apos;s make great things</h1>
            <h1>happen&nbsp;together.</h1>
          </p>
        </div>
      </div>
      <div className={'px-6 pt-4 md:px-0 flex justify-center flex-col text-pretty  lg:flex-row'}>
        <div className={'pb-4 md:pr-20'}>
          <h1 className={'pb-4 text-lg text-amber-400 font-semibold'}>
            Connect with us
          </h1>
          <div className={'font-extrabold text-2xl text-white  md:text-justify md:w-[28rem] pb-4 md:tracking-widest'}>
            <p>Feel free to ask Something</p>
            <p>We are just a call away.</p>
          </div>
          <div className={'text-sm text-white md:w-[31.5rem] tracking-wide flex-row'}>
            <Image src={contactus} preview={false} className={'lg:w-40 pb-4'} />
            <p>
              We have a passion to deliver the best quality IT solutions for all. Bringing you your desired service at
              the best value, so you&apos;ll always be able to find something you want at the right price.
              <div className={'text-sm '}>
                <div>
                  <p className={'pt-8 italic'}>e-mail id -
                    <a href={'mailto:contact@quantuminfotechs.com'} target={'_blank'}
                      className={'hover:text-amber-200'}
                      rel={'noreferrer'}> contact@quantuminfotechs.com</a>
                  </p>
                </div>
              </div>
            </p>
          </div>
        </div>
        <div className={'mt-5 lg:flex flex-col justify-center'}>
          <div className={'lg:flex justify-center'}>
            <div className={'lg:mr-2 lg:w-56 w-80 mb-2 lg:mb-0 border-2 border-yellow-500 rounded-lg'}>
              <Input placeholder={'Your Name'} className={'rounded-lg'} />
            </div>
            <div className={'lg:w-56 w-80  rounded-lg border-2 border-yellow-500'}>
              <Input placeholder={'Email Address'} className={'rounded-lg'} />
            </div>
          </div>
          <div className={'w-80 lg:w-full mt-2 rounded-lg border-2 border-yellow-500'}>
            <TextArea
              placeholder={'Question'}
              rows={4}
              className={'bg-contact-form-bg border-2 rounded-lg'} />
          </div>
          <div className={'py-4 '}>
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: '#fbbf24', // Amber 400 for initial background
                  colorPrimaryHover: '#16a34a', // Green 600 for hover background
                  colorText: '#000000', // Black text initially
                  colorTextHover: '#ffffff', // White text on hover
                },
                components: {
                  Button: {
                    colorBgContainer: '#fbbf24', // Initial background color
                    colorBgHover: '#ffffff', // Green background on hover
                    colorText: '#000000', // Initial text color
                    colorTextHover: '#ffffff', // White text on hover
                  },
                },
              }}>
              <Button
                type={'primary'}
                className={'w-80 md:w-full h-10 bg-opacity-45 text-black text-center  rounded-lg'}>
              Send Message
              </Button>
            </ConfigProvider>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ContactUs;
