import {Button, Image} from 'antd';
import {useEffect} from 'react';
import {Link, useParams} from 'react-router-dom';

import Footer from '../../../Footer/Footer';

import serviceinfo from './ServiceData.json';

const ServicesDetails = () => {
  const {id} = useParams();
  const details = serviceinfo.serviceinfo.find(service => service.id === Number(id));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!details) {
    return <div>Service not found</div>;
  }

  return (
    <div>
      <div
        style={{
          backgroundImage: window.innerWidth < 768 ? `url(${details.image1})` : `url(${details.image})`,
          height: '50vh',
          backgroundSize: 'cover',
          backgroundBlendMode: 'hard-light',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div className={'md:absolute md:pt-0 pt-64 md:mt-56 w-60 md:w-96'}>
          <h1 className={'text-4xl font-bold md:text-6xl text-yellow-500'}>{details.h1}</h1>
        </div>
      </div>
      <div className={'p-6 md:p-16 bg-zinc-950 text-white text-justify'}>
        <div className={'md:mb-12 mb-20'}>
          <Link to={'/services'}>
            <Button
              className={'md:w-28 mt-5 float-left md:mt-4 border-yellow-500 overflow-hidden p-[1px] focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2 focus:ring-offset-yellow-500'}>
              <span className={'absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-yellow-900'} />
              <span
                className={'h-full w-full cursor-pointer items-center justify-center bg-slate-950 px-3 py-1 font-medium text-white backdrop-blur-3xl'}>
                  Back
              </span>
            </Button>
          </Link>
        </div>
        <div className={'flex flex-col md:flex-row md:px-10'}>
          <div className={'md:py-12 flex items-center justify-center'}>
            <Image src={details.photo} preview={false} className={'max-w-min md:max-w-max'}
              style={{width: window.innerWidth < 786 ? 250 : 500}} />
          </div>
          <div className={'md:px-10'}>
            <h1 className={'text-center text-3xl md:text-6xl text-yellow-500 py-6'}>{details.title}</h1>
            <p className={'indent-8 py-4'}>{details.desc}</p>
            {details.content.features.map((features, index)=> (
              <div key={index}>
                <span className={'decoration-4 decoration-yellow-500 underline underline-offset-8'}>
                  <h1>
                    {features.title}
                  </h1>
                </span>
                <p className={'indent-8 py-4'}>{features.description}</p>
              </div>
            ))}
          </div>
        </div>
        <div className={'md:px-10'}>
          <span className={'decoration-4 decoration-yellow-500 underline underline-offset-8'}>
            <p>Summary:</p>
          </span>
          <p className={'indent-8 py-4'}>{details.summary}</p>
        </div>
      </div>

      <Footer />

    </div>
  );
};

export default ServicesDetails;
