import service from '../../../assets/Images/Servicesfinal1.png';
import Footer from '../../Footer/Footer';
import {AboutSlider} from '../../OurJourney/AboutUs/AboutSlider';
import {QIProvide} from '../index';

const QIServices = () => {
  return(
    <div>
      <div style={{
        backgroundImage: window.innerWidth < 768 ? `url(${service})` : `url(${service})`,
        height: '50vh',
        backgroundSize: 'cover',
        backgroundBlendMode: 'hard-light',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}>
        <div className={'md:absolute md:pt-0 pt-64 md:right-20 md:mt-20 md:w-96'}>
          <h1 className={'text-4xl font-bold md:text-6xl text-yellow-500'}>Our Services</h1>
          <p className={'text-white text-3xl'}>
            <h1>Transforming Challenges </h1>
            <h1>into Opportunities </h1>
            <h1>with Technology.</h1>
          </p>
        </div>
      </div>

      <QIProvide />
      <AboutSlider />
      <Footer />

    </div>
  );
};

export default QIServices;
