import {motion} from 'framer-motion';
import React, {useState} from 'react';
import {Link} from 'react-router-dom';

import API from '../../assets/Images/ServicesImg/API.jpg';
import business from '../../assets/Images/ServicesImg/business.jpg';
import CMS from '../../assets/Images/ServicesImg/CMS.png';
import crm from '../../assets/Images/ServicesImg/crm.jpg';
import cross from '../../assets/Images/ServicesImg/cross_platform.png';
import custom_web from '../../assets/Images/ServicesImg/custom_web.png';
import design from '../../assets/Images/ServicesImg/design.png';
import digital from '../../assets/Images/ServicesImg/digital.jpeg';
import eCommerce from '../../assets/Images/ServicesImg/eCommerce.jpg';
import ECommerce1 from '../../assets/Images/ServicesImg/ECommerce1.png';
import erp from '../../assets/Images/ServicesImg/ERP1.jpeg';
import erp2 from '../../assets/Images/ServicesImg/ERP2.jpeg';
import maintainance from '../../assets/Images/ServicesImg/maintainance.png';
import mobile from '../../assets/Images/ServicesImg/mobile3.jpeg';
import software from '../../assets/Images/ServicesImg/mobile4.jpeg';
import payment from '../../assets/Images/ServicesImg/payment-transformed.jpeg';
import performance from '../../assets/Images/ServicesImg/performance.png';
import planning from '../../assets/Images/ServicesImg/planning.png';
import qualityassurance from '../../assets/Images/ServicesImg/qualityassurance.png';
import responsive_design from '../../assets/Images/ServicesImg/responsive_design.png';
import robust from '../../assets/Images/ServicesImg/robust_technology.png';
import SEO from '../../assets/Images/ServicesImg/SEO.png';
import software_deployment from '../../assets/Images/ServicesImg/software_deployment.png';
import software_design from '../../assets/Images/ServicesImg/software_design.png';
import software_development from '../../assets/Images/ServicesImg/software_development.png';
import software_support from '../../assets/Images/ServicesImg/software_support.png';
import software_testing from '../../assets/Images/ServicesImg/software_testing.png';
import support from '../../assets/Images/ServicesImg/support.png';
import third_party from '../../assets/Images/ServicesImg/third_party.png';
import UI from '../../assets/Images/ServicesImg/UI1.png';
import UI1 from '../../assets/Images/ServicesImg/UI2.jpeg';
import web from '../../assets/Images/ServicesImg/web3.jpeg';

const QIServiceTypes = () => {
  const [selected, setSelected] = useState(0);

  const toggle = index => {
    if (selected === index) {
      return setSelected(null); // Close if it's the same section
    }
    setSelected(index); // Otherwise, open the clicked section
  };

  const ExpertiesImages = [
    {name: 'Mobile Application Development', img: mobile},
    {name: 'E-commerce Development', img: crm},
    {name: 'Web Development', img: web},
    {name: 'Enterprise Resource Planning (ERP) Development', img: erp2},
    {name: 'UI/UX Design', img: UI},
    {name: 'Digital Marketing', img: digital},
    {name: 'Custom Software Development', img: software},
  ];

  const EnterpriseImages = [
    {name: 'Customer Relationship Management (CRM) Systems', img: crm},
    {name: 'Enterprise Resource Planning (ERP) Systems', img: erp},
    {name: 'Business Intelligence and Analytics', img: business},
    {name: 'eCommerce Solutions', img: eCommerce},
  ];

  const MobileDevelopment = [
    {name: 'Cross-Platform Compatibility', img: cross},
    {name: 'User-Centric Design', img: design},
    {name: 'Robust Functionality', img: robust},
    {name: 'Secure Payment Integration', img: payment},
    {name: 'Custom API Development', img: API},
    {name: 'Third-Party Integrations', img: third_party},
    {name: 'Performance Optimization', img: performance},
    {name: 'Regular Updates and Maintenance', img: maintainance},
  ];

  const WebDevelopment = [
    {name: 'Responsive Design', img: responsive_design},
    {name: 'Custom Web Solutions', img: custom_web},
    {name: 'User-Friendly Interface', img: UI1},
    {name: 'Content Management Systems (CMS)', img: CMS},
    {name: 'E-commerce Capabilities', img: ECommerce1},
    {name: 'Search Engine Optimization (SEO)', img: SEO},
    {name: 'Quality Assurance and Testing', img: qualityassurance},
    {name: 'Ongoing Support and Maintenance', img: support},
  ];

  const ProcessImages = [
    {name: 'Discovery and Planning', img: planning},
    {name: 'Design', img: software_design},
    {name: 'Development', img: software_development},
    {name: 'Quality Assurance and Testing', img: software_testing},
    {name: 'Deployment', img: software_deployment},
    {name: 'Post-Launch Support', img: software_support},
  ];

  //todo: content paragraph

  const services = [
    {
      id: 1,
      title: 'EXPERTISE SERVICE',
      content: `Quantum Infotech specializes in delivering comprehensive technology solutions across multiple sectors. 
            Based on your expertise and portfolio, here are the areas where Quantum Infotech excels:`,
      images: ExpertiesImages,
    },
    {
      id: 2,
      title: 'MOBILE APP DEVELOPMENT',
      content: `At Quantum Infotech, we are committed to delivering high-quality mobile app development services that not only meet but
             exceed client expectations. Our focus on innovative features, user experience, and robust performance ensures 
            that your mobile application stands out in the competitive market.`,
      images: MobileDevelopment,
    },
    {
      id: 3,
      title: 'ENTREPRISE SERVICE',
      content: `Our mission is to transform businesses by providing innovative and intelligent solutions tailored to meet their unique challenges. We deliver cutting-edge technology and strategic insights that drive growth,
             efficiency, and scalability. From seamless integrations to data-driven decision-making, our comprehensive range of services empowers 
             organizations to stay ahead of the curve and thrive in a constantly evolving digital landscape. Together, let's build a smarter tomorrow.`,
      images: EnterpriseImages,
    },
    {
      id: 4,
      title: 'WEB DEVELOPMENT',
      content: `At Quantum Infotech, we specialize in delivering high-quality web development services that are both innovative and effective. 
            Our commitment to responsive design, user experience, and robust performance ensures that your online presence not only attracts visitors 
            but also converts them into loyal customers. With a focus on tailored solutions, 
            we help businesses establish a strong digital footprint in today's competitive landscape.`,
      images: WebDevelopment,
    },
    {
      id: 5,
      title: 'PROCESS',
      content: `At Quantum Infotech, our web development process is designed to be thorough, transparent, and collaborative. We prioritize 
            client involvement at every stage, ensuring that the final product aligns with their vision and business goals. Our focus on quality, security, and performance allows us to deliver 
            websites that not only look great but also provide exceptional user experiences and drive results`,
      images: ProcessImages,
    },
  ];

  return (
    <div className={'min-h-screen bg-black text-white flex flex-col items-center py-16'}>
      {/* Title */}
      <motion.h2
        className={'text-4xl font-semibold mb-10'}
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        transition={{duration: 1}}
      >
        Our Services
      </motion.h2>

      <motion.p
        className={'text-2xl font-medium mb-12 text-center'}
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        transition={{delay: 0.5, duration: 1}}
      >
        WHERE TECHNOLOGY MEETS VISION.
      </motion.p>

      {/* Accordion */}
      <div className={'w-full max-w-4xl mx-auto'}>
        {services.map((service, index) => (
          <div key={index} className={'border-b border-gray-700 pt-4'}>
            <motion.div
              className={'flex justify-between items-center p-5 cursor-pointer'}
              initial={{backgroundColor: '#1F1F1F'}}
              whileHover={{backgroundColor: '#333', scale: 1.02}}
              onClick={() => toggle(index)}
              transition={{duration: 0.3}}
            >
              <h3 className={'text-xl font-semibold'}>
                {`${service.id < 10 ? '0' : ''}${service.id} ${service.title}`}
              </h3>
              <span className={'text-3xl'}>
                {selected === index ? '✕' : '+'}
              </span>
            </motion.div>

            {/* Content of selected section */}
            {selected === index && (
              <motion.div
                className={'p-5 text-gray-400'}
                initial={{opacity: 0, height: 0}}
                animate={{opacity: 1, height: 'auto'}}
                transition={{duration: 0.4}}
              >
                {/* Content text */}
                {service.content && <p>{service.content}</p>}

                {/* Display standard images with hover animations */}
                <div className={'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mt-6'}>
                  {service.images.map((image, i) => (
                    <motion.div
                      key={i}
                      whileHover={{scale: 1.05}}
                      whileTap={{scale: 0.95}}
                      className={'relative overflow-hidden rounded-lg shadow-lg'}
                      transition={{duration: 0.3}}
                    >
                      <img src={image.img} alt={image.name}
                        className={'w-full h-40 object-cover'} />
                      <Link to={'/details/1'}>
                        <div
                          className={'absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center'}>
                          <p className={'text-white text-lg font-semibold text-center p-2'}>{image.name}</p>
                        </div>
                      </Link>
                    </motion.div>
                  ))}
                </div>
              </motion.div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default QIServiceTypes;
