import {Image} from 'antd';
import Slider from 'react-slick';

import android from '../../../../assets/Images/Logo/Android.png';
import angular from '../../../../assets/Images/Logo/Angular.png';
import content from '../../../../assets/Images/Logo/Content.png';
import dotnet from '../../../../assets/Images/Logo/dotnet.png';
import ERP from '../../../../assets/Images/Logo/ERP (1).png';
import IOS from '../../../../assets/Images/Logo/IOS.png';
import js from '../../../../assets/Images/Logo/JavaScript.png';
import native from '../../../../assets/Images/Logo/native.png';
import nodejs from '../../../../assets/Images/Logo/node.png';
import PHP from '../../../../assets/Images/Logo/PHP.png';
import reactjs from '../../../../assets/Images/Logo/reactjs.png';
import UI from '../../../../assets/Images/Logo/UIUX.png';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const data = [
  {
    id:1,
    img:reactjs,

  },
  {
    id:2,
    img:nodejs,
  },
  {
    id:3,
    img:js,
  },
  {
    id:4,
    img:android,
  },
  {
    id:5,
    img:angular,
  },
  {
    id:6,
    img:dotnet,
  },
  {
    id:7,
    img:ERP,
  },
  {
    id:8,
    img:native,
  },
  {
    id:9,
    img:UI,
  },
  {
    id:10,
    img:IOS,
  },
  {
    id:11,
    img:content,
  },
  {
    id:12,
    img:PHP,
  },
];

const settings = {
  dots: false,
  arrows:false,
  infinite: true,
  speed: 3000,
  autoplay: true,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplaySpeed: 1000,
  cssEase: 'linear',
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};

const AboutSlider = () => {
  return (
    <div>
      <div className={'flex justify-center bg-zinc-950 pt-9 pb-9'}>
        <div className={'w-80 lg:w-[170vh] lg:py-12'}>
          <Slider {...settings}>
            {data.map((id, img) => (
              <div key={id}>
                <div className={'w-28'}>
                  <Image src={id.img}
                    width={100}
                    height={100}
                    preview={false}
                  />
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default AboutSlider;
