import {Divider} from 'antd';
import {motion} from 'framer-motion';
import React from 'react';

const cardVariant = {
  hidden: {opacity: 0, y: 50},
  visible: {opacity: 1, y: 0},
};

const QISolution = () => {
  return (
    <div className={' bg-black text-white flex flex-col items-center py-16 '}>
      <motion.h2
        className={'text-4xl mb-10'}
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        transition={{duration: 1}}
      >
        IT Solutions
      </motion.h2>

      <motion.p
        className={'text-2xl font-medium mb-12 text-center '}
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        transition={{delay: 0.5, duration: 1}}
      >
        EXCELLENCE REDEFINED:
        <div className={'text-lg'}>UNLEASHING TAILORED
          SOLUTIONS FOR YOUR SUCCESS JOURNEY.</div>
      </motion.p>

      {/* Services Grid */}
      <div className={'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-2 lg:gap-8 px-6 lg:px-20 min-h-2'}>
        {/* Deliver On Time */}

        <motion.div
          className={'bg-gray-800 p-6 rounded-lg h-96 w-80 shadow-md'}
          variants={cardVariant}
          initial={'hidden'}
          animate={'visible'}
          transition={{delay: 0.1, duration: 0.8}}
        >
          <div className={'text-4xl mb-4'}>✨</div>
          <h3 className={'text-xl font-semibold'}>Deliver On Time</h3>
          <div className={'w-36'}><Divider className={'bg-white'} /></div>
          <p className={'mt-2'}>
            Timely precision, where promises meet performance, ensuring your project&apos;s success.
            At Quantum Infotech, we don’t just solve problems; we create opportunities for growth. Join us on a journey
            to harness the power of technology and elevate your business to new heights.
          </p>
        </motion.div>

        {/* Security */}
        <motion.div
          className={'bg-gray-800 p-6 rounded-lg shadow-md h-96 w-80 '}
          variants={cardVariant}
          initial={'hidden'}
          animate={'visible'}
          transition={{delay: 0.3, duration: 0.8}}
        >
          <div className={'text-4xl mb-4 '}>🛡️</div>
          <h3 className={'text-xl font-semibold'}>Security</h3>
          <div className={'w-36'}><Divider className={'bg-white'} /></div>
          <p className={'mt-2'}>
            Timely precision, where promises meet performance, ensuring your project&apos;s success.
            At Quantum Infotech, we don’t just solve problems; we create opportunities for growth. Join us on a journey
            to harness the power of technology and elevate your business to new heights.
          </p>
        </motion.div>

        {/* Flexibility */}
        <motion.div
          className={'bg-gray-800 p-6 rounded-lg shadow-md h-96 w-80 '}
          variants={cardVariant}
          initial={'hidden'}
          animate={'visible'}
          transition={{delay: 0.5, duration: 0.8}}
        >
          <div className={'text-4xl mb-4 '}>🧩</div>
          <h3 className={'text-xl font-semibold'}>Flexibility</h3>
          <div className={'w-36'}><Divider className={'bg-white'} /></div>
          <p className={'mt-2'}>
            Timely precision, where promises meet performance, ensuring your project&apos;s success.
            At Quantum Infotech, we don’t just solve problems; we create opportunities for growth. Join us on a journey
            to harness the power of technology and elevate your business to new heights.
          </p>
        </motion.div>

        {/* Pricing */}
        <motion.div
          className={'bg-gray-800 p-6 rounded-lg shadow-md h-96 w-80 '}
          variants={cardVariant}
          initial={'hidden'}
          animate={'visible'}
          transition={{delay: 0.7, duration: 0.8}}
        >
          <div className={'text-4xl mb-4 '}>💲</div>
          <h3 className={'text-xl font-semibold'}>Pricing</h3>
          <div className={'w-36'}><Divider className={'bg-white'} /></div>
          <p className={'mt-2'}>
            Timely precision, where promises meet performance, ensuring your project&apos;s success.
            At Quantum Infotech, we don’t just solve problems; we create opportunities for growth. Join us on a journey
            to harness the power of technology and elevate your business to new heights.
          </p>
        </motion.div>
      </div>
    </div>
  );
};

export default QISolution;
